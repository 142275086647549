<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Clientes</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div class="first-section w-100 mt-2 mt-md-0">
            <div class="d-flex justify-content-between align-items-center justify-content-lg-start">
              <div>
                <button
                  type="button"
                  class="button-tooltip m-0 d-md-none"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <RefreshTableIcon />
                </button>

                <button
                  type="button"
                  class="button-tooltip d-lg-none"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadCustomers()"
                >
                  <DownloadTableIcon />
                </button>
              </div>

              <div
                class="d-flex align-items-center justify-content-between filter"
                @click="showFilters = !showFilters"
              >
                <FiltersIcon />
                <div>Filtrar clientes</div>
              </div>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center">
            <button
              type="button"
              class="button-tooltip m-0 d-none d-md-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>

            <button
              type="button"
              class="button-tooltip d-none d-md-block"
              v-b-tooltip.hover
              title="Descargar"
              @click="downloadCustomers()"
            >
              <DownloadTableIcon />
            </button>

            <SearchBar
              placeholder="Buscar por nombres, apellidos y n° de documento"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>
                Nombres y <br />
                apellidos
              </th>
              <th>
                Tipo de <br />
                documento
              </th>
              <th>
                N° de <br />
                documento
              </th>
              <th>Celular</th>
              <!-- <th>Referencia</th>
            <th>Dscto.</th> -->
              <th>
                Fecha de <br />
                registro
              </th>
              <th>Fecha de <br />última compra</th>
              <th>N° de compras</th>
              <th>
                Importe de <br />
                compras
              </th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="pl-md-3">{{ `${o.firstName} ${o.lastName}` }}</td>
              <td class="text-center">
                {{ getIdentifierType(o.identifierType).label }}
              </td>
              <td class="text-center">{{ o.identifier }}</td>
              <td class="text-center">{{ o.mobile }}</td>
              <td class="text-center" v-local-date="o.createdAt"></td>
              <td class="text-center" v-local-date="o.lastPurchasedAt"></td>
              <td class="text-center" v-int="o.purchaseCount"></td>
              <td class="text-center" v-decimal:pen="o.purchaseTotal"></td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      :to="{ name: 'clients-edit', params: { id: o.id } }"
                      text="Editar"
                    />
                    <DropdownItem
                      :text="getStatus(o.status).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem
                      text="Ver datos"
                      :to="{ name: 'clients-details', params: { id: o.id } }"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  CloseBorderRoundedIcon,
  ConfirmationModal,
  ConfirmationModalMixin,
  Constants,
  DropdownItem,
  DropdownTable,
  FilterMenu,
  FilterMixin,
  FiltersIcon,
  PaginationMixin,
  RefreshTableIcon,
  DownloadTableIcon,
  SearchBar,
  Util,
} from "wize-admin";

import fileDownload from "js-file-download";
import moment from "moment";

import { Constants as LocalConstants } from "@/core/utils";

import { CustomerService, ReferenceService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
    DownloadTableIcon,
    ConfirmationModal,
  },
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.CustomerStatus.ACTIVE,
            LocalConstants.CustomerStatus.INACTIVE,
          ],
        },
        {
          label: "Realizó compra",
          key: "hasBought",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.CustomerHasBoughtValues.YES,
            LocalConstants.CustomerHasBoughtValues.NOT,
          ],
        },
        {
          label: "Tipo de documento",
          key: "identifierTypes",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            Constants.IdentifierType.DNI,
            Constants.IdentifierType.CE,
            Constants.IdentifierType.RUC,
            Constants.IdentifierType.PASSPORT,
          ],
        },
        {
          label: "Fecha de registro",
          key: "createdAtDate",
          type: Constants.TypeFilters.DATE,
        },
        {
          label: "Referencia",
          key: "referenceIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        let filters = { ...this.filters };
        if (filters.createdAtDate) {
          filters.createdAtStart = moment(
            filters.createdAtDate.startDate
          ).format("YYYY-MM-DD");
          filters.createdAtEnd = moment(filters.createdAtDate.endDate).format(
            "YYYY-MM-DD"
          );
        }
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...filters,
        };
        const response = await CustomerService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.CustomerStatus);
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);

        let status =
          this.selectedOption.status ===
          LocalConstants.CustomerStatus.ACTIVE.key
            ? LocalConstants.CustomerStatus.INACTIVE.key
            : LocalConstants.CustomerStatus.ACTIVE.key;

        const resp = (
          await CustomerService.toggleStatus(this.selectedOption.id, status)
        ).payload;

        const item = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (item) {
          const index = this.list.indexOf(item);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteCustomer();
      if (this.confirmationModal.action === "update") this.changeStatus();
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del cliente?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete() {
      alert("Ver datos");
    },
    async deleteCustomer() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await CustomerService.delete(this.selectedOption.id))
          .payload;

        const serie = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (serie) {
          const index = this.list.indexOf(serie);
          this.list.splice(index, 1);
          this.total--;
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadCustomers() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          search: this.search,
          ...this.filters,
        };
        const response = await CustomerService.downloadCustomersList(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    try {
      this.$store.dispatch("app/loading", true);
      const resp = await ReferenceService.listActive();

      let referenceFilter = this.filterOptions.find(
        (item) => item.key === "referenceIds"
      );

      referenceFilter.options = [];
      resp.payload.forEach((it) => {
        referenceFilter.options.push({ key: it.id, label: it.description });
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.$store.dispatch("app/loading", false);
    }

    await this.loadData();
  },
};
</script>

<style lang="stylus"></style>
