<template>
  <div class="page-container categories-list" v-if="attribute">
    <div class="page-title-container">
      <h1 class="page-title">Atributo: {{ attribute.name }}</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div class="d-flex d-lg-none justify-content-between mb-2">
          <div>
            <button
              type="button"
              class="button-tooltip d-lg-none m-0"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>
          </div>

          <div>
            <router-link
              :to="{ name: 'config-attributes-list' }"
              class="button button-light back-button mr-2"
            >
              Volver
            </router-link>

            <b-button
              :to="{ name: 'config-attributes-create-value' }"
              variant="none"
              class="button button-primary"
              >Agregar</b-button
            >
          </div>
        </div>

        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div class="first-section w-100 mt-2 mt-md-0">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex align-items-center justify-content-between filter"
                @click="showFilters = !showFilters"
              >
                <FiltersIcon />
                <div>Filtrar valores</div>
              </div>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center">
            <button
              type="button"
              class="button-tooltip d-none d-lg-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>

            <SearchBar
              placeholder="Buscar por descripción"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />

            <div class="d-none d-lg-flex items-center">
              <router-link
                :to="{ name: 'config-attributes-list' }"
                class="button button-light back-button mx-2"
              >
                Volver
              </router-link>

              <b-button
                :to="{ name: 'config-attributes-create-value' }"
                variant="none"
                class="button button-primary"
                >Agregar</b-button
              >
            </div>
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">
                {{ o.name }}
              </td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.isActive).color}`">{{
                  getStatus(o.isActive).label
                }}</span>
              </td>

              <td class="text-center d_option">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem :to="{ name: 'config-attributes-edit-value', params: { attributeId: attribute.id, valueId: o.id } }" text="Editar"
                    />
                    <DropdownItem
                      :text="getStatus(o.isActive).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  // DownloadTableIcon,
  ConfirmationModal,
  FilterMenu,
  FiltersIcon,
  RefreshTableIcon,
  CloseBorderRoundedIcon,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { AttributeService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    RefreshTableIcon,
    CloseBorderRoundedIcon,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      attributeId: this.$route.params.attributeId,
      attribute: null,
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "isActive",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ProductsAttributesStatus.ACTIVE,
            LocalConstants.ProductsAttributesStatus.INACTIVE,
          ],
        },
      ],
    };
  },
  watch: {
    "$route.params.attributeId": function () {
      if (this.$route.params.attributeId)
        this.load(this.$route.params.attributeId);
    },
  },
  methods: {
    async loadData(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await AttributeService.get(id);
        this.attribute = { ...response.payload };
        this.list = [...this.attribute.values];
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getAttributeType(key) {
      return Util.searchValue(key, LocalConstants.ProductsAttributes);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ProductsAttributesStatus);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteObject();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await AttributeService.updateAttributeValueStatus(
            this.attributeId,
            this.selectedOption.id,
            !this.selectedOption.isActive
          )
        ).payload;

        const value = this.list.find((o) => {
          return o.id === resp.id;
        });

        if (value) {
          const index = this.list.indexOf(value);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
        this.$store.commit('app/setAttributes', null)
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del valor?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteObject() {
      try {
        await this.$store.dispatch("app/loading", true);
        await AttributeService.deleteAttributeValue(
          this.attributeId,
          this.selectedOption.id
        );

        await this.loadData(this.attributeId);
        this.$store.commit('app/setAttributes', null)
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    setFilters(options) {
      this.showFilters = false;

      this.filters = {
        ...options,
      };

      this.list = this.attribute.values.filter(
        (item) => item.isActive === this.filters.isActive
      );

      if (this.search) {
        this.list = this.list.filter((item) => item.name.includes(this.search));
      }
    },
    deleteFilter(label) {
      delete this.filters[label];
      this.filters = { ...this.filters };

      this.loadData(this.$route.params.attributeId);
    },
    onSearch(keyword) {
      this.search = keyword;

      if (this.search) {
        this.list = this.attribute.values.filter((item) =>
          item.name.includes(this.search)
        );
      } else {
        this.list = [...this.attribute.values];
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    if (this.$route.params.attributeId)
      this.loadData(this.$route.params.attributeId);
  },
};
</script>

<style lang="stylus" scoped>
@import "~wize-admin/dist/styles/variables"
.back-button
  &:hover
    background-color: white
    color: rgba(color-dark, .85)
</style>
