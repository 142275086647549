<template>
  <section class="delivery-list">
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Entregas y envíos</h1>
      <div class="dashboard-container">
        <div class="dashboard-card p-0 border-0">
          <div class="table-container position-relative">
            <div class="table-header">
              <div
                class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
              >
                <div class="w-100">
                  <div
                    class="d-flex justify-content-between align-items-center justify-content-lg-start"
                  >
                    <FilterDeliveryList
                      :deletedOptions="deletedOptions"
                      @success="setFilters"
                    />

                    <div>
                      <button
                        type="button"
                        class="button-tooltip d-md-none mr-0"
                        v-b-tooltip.hover
                        title="Descargar"
                        @click="downloadList()"
                      >
                        <DownloadTableIcon />
                      </button>
                    </div>
                  </div>
                </div>

                <section class="w-100 d-flex align-items-center mt-2 mt-md-0">
                  <button
                    type="button"
                    class="button-tooltip d-none d-md-block"
                    v-b-tooltip.hover
                    title="Descargar"
                    @click="downloadList()"
                  >
                    <DownloadTableIcon />
                  </button>

                  <SearchBar
                    placeholder="Buscar por departamento, provincia o distrito"
                    autocomplete="off"
                    class="w-100 m-0"
                    @search="onSearch"
                    @input="search = $event"
                  />
                </section>
              </div>

              <div class="w-100 d-flex flex-wrap align-items-center mt-2">
                <div v-for="val in filtersList" v-bind:key="val">
                  <span
                    class="d-flex justify-content-between mr-2 filter-secondary"
                    v-if="val"
                  >
                    {{ val }}
                    <button
                      type="button"
                      class="p-0 bg-transparent border-0"
                      @click="deleteFilter(val)"
                    >
                      <CloseBorderRoundedIcon class="delete-filter-item" />
                    </button>
                  </span>
                </div>
              </div>

              <section
                class="d-flex align-items-center position-relative search-container"
              >
                <div
                  class="search-results-container bg-white mt-1 py-3"
                  v-if="showSearchResults"
                >
                  <ul class="list-unstyled mb-0" v-if="searchItems.length">
                    <li
                      class="search-results-item cursor-pointer"
                      v-for="item in searchItems"
                      :key="item.key"
                      @click="selectSearchItem(item)"
                    >
                      {{ item.labelModified || item.label }}
                    </li>
                  </ul>

                  <p class="m-0 px-3" v-else>No se encontraron resultados.</p>
                </div>

                <div
                  class="search-results-overlay"
                  @click="showSearchResults = false"
                  v-if="showSearchResults"
                ></div>
              </section>
            </div>
            <div class="table-responsive mb-0">
              <table class="custom-table custom-form w-100" v-if="list.length">
                <thead>
                  <tr>
                    <th>Localidad</th>
                    <th>Tipo de envío</th>
                    <th>Tipo de entrega</th>
                    <th>Días de entrega</th>
                    <th>Rango de horario</th>
                    <th>Precio</th>
                    <th class="pr-3">Habilitar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in list" :key="index">
                    <td class="text-md-center pl-2">
                      {{ item.description }}
                    </td>

                    <td class="px-3 px-md-2">
                      <FormSelect
                        id="shippingType"
                        v-model="item.type"
                        :items="shippingTypes"
                        defaultOption="Elegir"
                        class="shipping-type-select"
                        @change="setDeliveryDaysOptions(item)"
                      />
                    </td>

                    <td>
                      <FormSelect
                        id="deliveryDaysOptions"
                        v-model="item.dayType"
                        :items="item.deliveryDaysOptions"
                        defaultOption="Elegir"
                        class="delivery-days-options-select"
                      />
                    </td>
                    <td class="px-3 px-md-2">
                      <SelectDays
                        class="delivery-days"
                        :oldSelection="item.days"
                        :daysList="daysList"
                        @selected="selectedDays($event, index)"
                        v-if="
                          item.dayType ==
                          LocalConstants.DeliveryDaysOptions.FIXED_DAYS.key
                        "
                      >
                      </SelectDays>

                      <FormInput
                        type="text"
                        :min="0"
                        placeholder="De 3 a 4 días hábiles"
                        class="delivery-days"
                        v-model="item.daysText"
                        v-else
                      />
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <SelectTimeRange
                          class="time-option-select mr-2"
                          :options="timeRangeOptions"
                          :oldValue="item.startHour"
                          @selected="setTimeRange($event, index, 'startHour')"
                        />

                        <SelectTimeRange
                          class="time-option-select"
                          :options="timeRangeOptions"
                          :oldValue="item.endHour"
                          @selected="setTimeRange($event, index, 'endHour')"
                        />
                      </div>
                    </td>
                    <td class="text-center px-3 px-md-2">
                      <FormInput
                        type="number"
                        :min="0"
                        placeholder="Ingresar"
                        class="price-field mx-auto"
                        v-model="item.amount"
                      />
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        switch
                        v-model="item.isActive"
                        name="check-button"
                        @change="validateFields(item)"
                      >
                      </b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="form-group-buttons text-right p-4 d-none d-xl-block">
                <button
                  class="button button-primary ml-2"
                  type="button"
                  @click="save"
                >
                  Guardar
                </button>
              </div>
            </div>

            <div class="form-group-buttons text-right p-4 d-xl-none">
              <button
                class="button button-primary ml-2"
                type="button"
                @click="save"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  SearchBar,
  FormInput,
  FormSelect,
  PaginationMixin,
  CloseBorderRoundedIcon,
  DownloadTableIcon,
  Alert,
  Util,
} from "wize-admin";

import { Helpers } from "@/core/utils";
import { Constants as LocalConstants } from "@/core/utils";

import { DeliveryService, UbigeoService } from "@/core/services";

import fileDownload from "js-file-download";

import SelectDays from "@/core/components/common/SelectDays";
import SelectTimeRange from "@/core/components/common/SelectTimeRange";
import FilterDeliveryList from "../Components/delivery-list/FilterDeliveryList";

export default {
  mixins: [PaginationMixin],
  data() {
    return {
      LocalConstants,
      filterOptions: {
        departmentId: null,
        provinceId: null,
        districtId: null,
      },
      shippingTypes: [
        LocalConstants.ShippingTypes.INDEPENDENT,
        LocalConstants.ShippingTypes.COURIER,
      ],
      daysList: [
        {
          status: false,
          label: "Todos los días",
          key: "all",
          unique: true,
        },
        {
          status: false,
          label: "Lunes",
          key: "l",
        },
        {
          status: false,
          label: "Martes",
          key: "m",
        },
        {
          status: false,
          label: "Miércoles",
          key: "mi",
        },
        {
          status: false,
          label: "Jueves",
          key: "j",
        },
        {
          status: false,
          label: "Viernes",
          key: "v",
        },
        {
          status: false,
          label: "Sábado",
          key: "s",
        },
        {
          status: false,
          label: "Domingo",
          key: "d",
        },
      ],
      list: [],
      ubigeos: [],
      departments: [],
      provinces: [],
      districts: [],
      timeRangeOptions: [],
      searchItems: [],
      showSearchResults: false,
      deletedOptions: null,
      filteredUbigeoId: null,
    };
  },
  async created() {
    this.$store.dispatch("app/loading", true);

    // Generar horarios a mostrar en selector
    this.timeRangeOptions = Helpers.generateHours();

    await this.getUbigeos();

    this.loadData();
  },
  components: {
    FilterDeliveryList,
    SearchBar,
    FormInput,
    FormSelect,
    SelectDays,
    SelectTimeRange,
    CloseBorderRoundedIcon,
    DownloadTableIcon,
  },
  methods: {
    async getUbigeos() {
      try {
        const data = await UbigeoService.getUbigeos();
        this.ubigeos = data.payload.map((o) => {
          return { key: o.id, label: o.description, parentId: o.parentId };
        });
      } catch (error) {
        Alert.error(error);
      }
    },
    async loadData(filterOptions) {
      // Se espera recibir los filtros como parámetros en algunos casos
      // de lo contrario se usan los filtros generales

      this.list = [];

      try {
        this.$store.dispatch("app/loading", true);

        let departmentId = filterOptions
          ? filterOptions.departmentId
          : this.filterOptions.departmentId;
        let provinceId = filterOptions
          ? filterOptions.provinceId
          : this.filterOptions.provinceId;
        let districtId = filterOptions
          ? filterOptions.districtId
          : this.filterOptions.districtId;

        let params = {};

        if (departmentId && !provinceId) {
          params.ubigeoId = departmentId;
        } else if (provinceId && !districtId) {
          params.ubigeoId = provinceId;
        } else if (districtId) {
          params.ubigeoId = districtId;
        }

        this.filteredUbigeoId = params.ubigeoId;

        const data = await DeliveryService.list(params);

        this.list = data.payload;
        this.list.forEach((item) => {
          item.days = item.days || [];
          this.setDeliveryDaysOptions(item);
        });

        // this.list = JSON.parse(JSON.stringify(this.list));
      } catch (error) {
        Alert.error(error);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    setFilters(e) {
      if (this.search) this.search = null;

      this.filterOptions = e;

      this.loadData();
    },
    deleteFilter(val) {
      let key = val.split(":");
      key = key[0];

      if (key === "Departamento") {
        // En caso se elimine un departamento, también se debe eliminar sus descendientes
        this.filterOptions.departmentId = null;
        this.filterOptions.provinceId = null;
        this.filterOptions.districtId = null;
      } else if (key === "Provincia") {
        this.filterOptions.provinceId = null;
        this.filterOptions.districtId = null;
      } else if (key === "Distrito") {
        this.filterOptions.districtId = null;
      }

      // Enviar prop a componente "FilterDeliveryList" para actualizar selección
      this.deletedOptions = { ...this.filterOptions };

      this.loadData();
    },
    selectedDays(list, index) {
      this.list[index].days = [...list];
    },
    setTimeRange(value, index, type) {
      this.list[index][type] = value.label;
    },
    async save() {
      this.$store.dispatch("app/loading", true);

      let data = [...this.list];

      data.forEach((item) => {
        delete item.deliveryDaysOptions;
        if (item.dayType == LocalConstants.DeliveryDaysOptions.FIXED_DAYS.key) {
          item.daysText = null;
        } else {
          item.days = [];
        }
      });

      try {
        await DeliveryService.save(data);

        Alert.success("Se almacenó la información correctamente");

        this.loadData();
      } catch (error) {
        Alert.error(error.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    onSearch(e) {
      if (!e) {
        this.searchItems = [];
        this.showSearchResults = false;

        this.filterOptions = {
          departmentId: null,
          provinceId: null,
          districtId: null,
        };

        // Enviar prop a componente "FilterDeliveryList" para actualizar selección
        this.deletedOptions = { ...this.filterOptions };

        this.loadData();

        return;
      }

      this.searchItems = this.ubigeos.filter((item) =>
        item.label.toLowerCase().includes(e.toLowerCase())
      );

      // Buscar si hay otros elementos con el mismo nombre
      this.searchItems.forEach((item) => {
        let existsInList = this.searchItems.filter(
          (item2) => item2.label == item.label
        );

        // No puede ser ">=" ya que sería el mismo item
        if (existsInList.length > 1) {
          if (item.key.length == 2)
            item.labelModified = `${item.label} - Departamento`;
          if (item.key.length == 4)
            item.labelModified = `${
              item.label
            } - Provincia de ${this.getParentLabel(item.parentId)}`;
          if (item.key.length >= 6)
            item.labelModified = `${
              item.label
            } - Distrito de ${this.getParentLabel(item.parentId)}`;
        }
      });

      this.showSearchResults = true;
    },
    async selectSearchItem(item) {
      this.filterOptions = {
        departmentId: null,
        provinceId: null,
        districtId: null,
      };

      // Enviar prop a componente "FilterDeliveryList" para actualizar selección
      this.deletedOptions = { ...this.filterOptions };

      let filterOptions = { ...this.filterOptions };

      // if (item.key.length == 2) filterOptions.departmentId = item.key

      // Solo se envían los 2 primeros dígitos
      if (item.key.length == 4) filterOptions.provinceId = item.key.slice(0, 2);

      // Solo se envían los 4 primeros dígitos
      if (item.key.length >= 6) filterOptions.districtId = item.key.slice(0, 4);

      await this.loadData(filterOptions);

      // En caso sea departamento
      let exists = this.list.find((i) => i.id == item.key);
      this.list = [exists];

      this.showSearchResults = false;
    },
    getParentLabel(key) {
      let item = this.ubigeos.find((item) => item.key == key);
      return item ? item.label : "";
    },
    setDeliveryDaysOptions(item) {
      if (item.type == LocalConstants.ShippingTypes.INDEPENDENT.key) {
        item.deliveryDaysOptions = [
          LocalConstants.DeliveryDaysOptions.FIXED_DAYS,
          LocalConstants.DeliveryDaysOptions.BUSINESS_DAYS,
        ];
      } else {
        item.deliveryDaysOptions = [
          LocalConstants.DeliveryDaysOptions.BUSINESS_DAYS,
        ];
      }
    },
    validateFields(item) {
      if (item.isActive) {
        let obj = { ...item };

        item.dayType == 1 ? delete obj.daysText : delete obj.days;

        for (const key in obj) {
          if (item[key] == null || item[key] == []) {
            item.isActive = false;
            Alert.error(
              "Es necesario completar todos los campos para poder habilitar la ubicación."
            );
            break;
          }
        }
      }
    },
    async downloadList() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DeliveryService.downloadList({
          ubigeoId: this.filteredUbigeoId,
        });
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    filtersList: function () {
      let data = {
        ...this.filterOptions,
      };

      for (const key in data) {
        let item = this.ubigeos.find((u) => u.key == data[key]);

        if (item) {
          // data[key] = item.label

          if (key === "departmentId") {
            data[key] = `Departamento: ${item.label}`;
          } else if (key === "provinceId") {
            data[key] = `Provincia: ${item.label}`;
          } else if (key === "districtId") {
            data[key] = `Distrito: ${item.label}`;
          }
        }
      }

      return data;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/delivery-list.styl';
</style>
